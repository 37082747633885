import "../styles/index.css";

console.log("App Ready");

const BASE_URL =
  "https://raw.githubusercontent.com/HubSpotWebTeam/CodeExercise/main/src/js/data/data.json";

const main = document.getElementById("main");
const div = document.getElementsByClassName("movie-container");
const list1 = document.getElementById("list1");
const list2 = document.getElementById("list2");
const filterInput = document.getElementById("filterInput");
const reset = document.getElementById("reset");

let globalData = [];

const extractYears = (data) => {
  // Remove duplicates from dropdown
  const map = new Map();
  data.forEach((item) => {
    map.set(item["year"], item["year"]);
  });

  return Array.from(map.values()).sort((a, b) => b.localeCompare(a));
};

const extractGenres = (data) => {
  // Remove duplicates from dropdown
  const map = new Map();
  data.forEach(({ genre = [] }) => {
    genre.forEach((item) => {
      map.set(item, item);
    });
  });

  return Array.from(map.values()).sort((a, b) => a.localeCompare(b));
};

const getMovies = (url) => {
  fetch(url)
    .then((res) => res.json())
    .then((data) => {
      const list = data.media.sort((a, b) => a.title.localeCompare(b.title));
      showMovies(list);
      globalData = list;
      // console.log(globalData);
    });
};

const showMovies = (data) => {
  main.innerHTML = "";

  // injecting film cards

  data.forEach((movie) => {
    const { title, year, poster, genre, type } = movie;
    const movieEl = document.createElement("div");
    var genreWithSpacing = genre.join(", ");
    movieEl.classList.add("movie-container");
    movieEl.innerHTML = `
       
            <img src="${poster}" alt="image">
            <div class="movie-info">
            <h3>${title} <span>(${year})</span></h3>
            
            </div>

            <div class="genres">
            Genres: ${genreWithSpacing}
            </div>
        `;

    main.appendChild(movieEl);
  });

  // GENRE DROPDOWN LIST

  list1.addEventListener("click", (e) => {
    e.currentTarget.classList.contains("visible")
      ? e.currentTarget.classList.remove("visible")
      : e.currentTarget.classList.add("visible");
  });

  const genreDropDown = () => {
    list1.innerHTML = "";
    const genreEl = document.createElement("span");
    genreEl.className = "anchor";
    genreEl.textContent = "GENRES";
    list1.appendChild(genreEl);
    const ul = document.createElement("ul");
    ul.classList.add("items");
    list1.appendChild(ul);

    const list = extractGenres(data);

    list.forEach((genre) => {
      const genreEl = document.createElement("li");
      genreEl.innerHTML = `
          <input type="checkbox" />${genre}  
        `;
      ul.appendChild(genreEl);
    });
  };

  // YEAR DROPDOWN LIST

  list2.addEventListener("click", (e) => {
    e.currentTarget.classList.contains("visible")
      ? e.currentTarget.classList.remove("visible")
      : e.currentTarget.classList.add("visible");
  });

  const yearDropDown = () => {
    list2.innerHTML = "";
    const titleEl = document.createElement("span");
    titleEl.className = "anchor";
    titleEl.textContent = "YEAR";
    list2.appendChild(titleEl);
    const ul = document.createElement("ul");
    ul.style.width = "80px";
    ul.classList.add("items");
    list2.appendChild(ul);

    const list = extractYears(data);

    list.forEach((year) => {
      const yearEl = document.createElement("li");
      yearEl.innerHTML = `
          <input type="checkbox" />${year}  
        `;
      ul.appendChild(yearEl);
    });
  };

  genreDropDown();
  yearDropDown();
};

// FILTER BY INPUT (fuzzy search)

const filterNames = () => {
  /**
   *  Get value of the input. We make sure it case
   *  insensitive by making everything to be uppercased
   * */
  let filterValue = document.getElementById("filterInput").value.toUpperCase();

  // Get pulled names

  for (let i = 0; i < div.length; i++) {
    let h3 = div[i].getElementsByTagName("h3")[0];
    // if matched
    if (h3.innerHTML.toUpperCase().indexOf(filterValue) > -1) {
      div[i].style.display = "";
    } else {
      div[i].style.display = "none";
    }
  }
};

/**
 * Add event listener. After inputting vallues
 * into field we calling filterNames function
 * */
filterInput.addEventListener("keyup", filterNames);

// CLEAR FILTERS

const resetButton = () => {
  const resetAll = () => {
    document.querySelector('input[name="radio"]:checked').checked
      ? (document.querySelector('input[name="radio"]:checked').checked = false)
      : true;
    getMovies(BASE_URL);
  };
  reset.addEventListener("click", resetAll);
};

resetButton();

// FILTER BY RADIO BUTTON

const filterType = (e) => {
  const type = e.target.value;
  showMovies(globalData.filter((movie) => movie.type === type.toLowerCase()));
};

document.getElementById("radioMovies").addEventListener("change", filterType);
document.getElementById("radioBooks").addEventListener("change", filterType);

getMovies(BASE_URL);
